import { ApplicationState, ApplicationEvent, ApplicationEventListener } from './Ridingazua.ApplicationState';
import { Point, Section, Waypoint, WaypointType } from '../common/Ridingazua.Model';
import { Resources } from './Ridingazua.Resources';
import { MenuItem, MenuController } from './Ridingazua.MenuController';
import { WaypointDialogContorller, AddWaypointTask } from './Ridingazua.WaypointDialogController';
import { TaskManager } from './Ridingazua.TaskManager';
import { SplitTask, SectionEditor } from './Ridingazua.SectionEditor';
import { StorageController } from './Ridingazua.StorageController';
import { sprintf } from 'sprintf-js';
import { HTMLUtility } from './Ridingazua.HTMLUtility';
import { Geometry } from '../common/Ridingazua.Geometry';
import { Utility } from '../common/Ridingazua.Utility';
import { OverlayImageMapSettingsDialogController } from './Ridingazua.OverlayImageMapSettingsDialogController';
import { MapType, MapTypeHelper } from './Ridingazua.MapType';
import { LatLng, LatLngBounds, MapWrapper, MapProvider } from './Ridingazua.MapWrapper';
import { MapConstants } from './Ridingazua.MapConstants';
import { GoogleMapWrapper } from './Ridingazua.GoogleMapWrapper';
import { KakaoMapWrapper } from './Ridingazua.KakaoMapWrapper';

export class MapController implements ApplicationEventListener {
    static initializeMap() {
        let initializeMapNeeded = (this.map == null || !this.map.availableMapTypes.includes(this.selectedMapType));

        if (!initializeMapNeeded) {
            return;
        }

        this.resetDivMap();

        let mapProvider = MapTypeHelper.mapProvider(this.selectedMapType);

        switch (mapProvider) {
            case MapProvider.GOOGLE:
                ApplicationState.map = new GoogleMapWrapper();
                break;

            case MapProvider.KAKAKO:
                ApplicationState.map = new KakaoMapWrapper();
                break;
        }
    }

    private static resetDivMap() {
        // 기존 div-map 요소를 찾아서 삭제
        const oldDiv = document.getElementById('div-map');
        const newDiv = document.createElement('div');
        newDiv.id = 'div-map';

        const parent = oldDiv.parentNode;
        parent.insertBefore(newDiv, oldDiv);
        parent.removeChild(oldDiv);
    }

    private static get map(): MapWrapper {
        return ApplicationState.map;
    }

    private get map(): MapWrapper {
        return ApplicationState.map;
    }

    private static _selectedMapType: MapType = MapTypeHelper.mapTypeBy(
        parseInt(
            StorageController.get('selectedMapType')
        )
    );

    static get selectedMapType(): MapType {
        return this._selectedMapType;
    }

    static set selectedMapType(value: MapType) {
        if (this._selectedMapType == value) {
            return;
        }

        this._selectedMapType = value;
        StorageController.set('selectedMapType', `${value}`);

        if (this.map.availableMapTypes.includes(value)) {
            this.map.mapType = this.selectedMapType;
        } else {
            this.initializeMap();
        }

        ApplicationState.executeListeners(ApplicationEvent.SELECTED_MAP_TYPE_CHANGED);
    }

    static addListenerToApplicationState() {
        ApplicationState.addListener(MapController);
    }

    handleApplicationEvent(event: ApplicationEvent, arg: any | null): void {
        MapController.handleApplicationEvent(event, arg);
    }

    static handleApplicationEvent(event: ApplicationEvent, arg: any): void {
        if (!this.map) {
            return;
        }

        switch (event) {
            case ApplicationEvent.SET_CURSOR:
                let point = arg as Point;
                if (!point) {
                    return;
                }

                MapController.setCursorInfoLayer(point);

                break;

            case ApplicationEvent.REMOVE_CURSOR:
                this.map.setCursorMarker(null);
                MapController.removeCursorInfoLayer();
                break;

            case ApplicationEvent.POSITION_WATCH_STARTED:
                toastr.info(Resources.text.finding_my_location);
                break;

            case ApplicationEvent.POSITION_RECEIVED:
                if (!this.map) {
                    return;
                }

                let position = arg as GeolocationPosition;
                let center = new LatLng(position.coords.latitude, position.coords.longitude);
                this.map.jump(
                    center,
                    this.map.defaultZoomToPoint
                );
                this.map.setMyLocationMarker(center);
                break;

            case ApplicationEvent.POSITION_FAILED:
                let errorMessage = arg && arg.message;
                toastr.error(`${Resources.text.failed_to_my_location}(${errorMessage})`);
                this.map.setMyLocationMarker(null);
                break;
        }
    }

    static didMapCenterChange() {
        this.resetDivInfoLayerPositionIfRequired();
    }

    static didMapZoomChange() {
        this.resetDivInfoLayerPositionIfRequired();
    }

    /**
    * 코스의 Polyline 상에 현재 커서가 가리키는 지점을 표시하는 marker를 삽입한다.
    * @param point
    */
    private static setCursorMarker(point: Point) {
        this.map.setCursorMarker(
            new LatLng(point.latitude, point.longitude)
        );
    }

    private static divInfoLayer: HTMLDivElement;
    private static spanInfoLayerDirection: HTMLSpanElement;
    private static divInfoLayerText: HTMLDivElement;

    private static createDivInfoLayer() {
        if (this.divInfoLayer) {
            return;
        }

        let divInfoLayer = document.createElement('div');
        divInfoLayer.classList.add('cursor-info-layer');
        this.divInfoLayer = divInfoLayer;

        let divInfoLayerDirection = document.createElement('div');
        divInfoLayer.appendChild(divInfoLayerDirection);
        divInfoLayerDirection.style.textAlign = 'center';

        let spanDirection = document.createElement('span');
        divInfoLayerDirection.appendChild(spanDirection);
        this.spanInfoLayerDirection = spanDirection;
        spanDirection.textContent = '→';
        spanDirection.style.display = 'inline-block';
        spanDirection.style.fontWeight = 'bolder';
        spanDirection.style.fontSize = '15pt';
        spanDirection.style.width = '20px';
        spanDirection.style.height = '20px';
        spanDirection.style.lineHeight = '20px';
        spanDirection.style.textAlign = 'center';
        spanDirection.style.verticalAlign = 'middle';

        let divInfoLayerText = document.createElement('div');
        this.divInfoLayerText = divInfoLayerText;
        divInfoLayer.appendChild(divInfoLayerText);
        divInfoLayerText.style.marginTop = '5px';
        divInfoLayerText.style.fontSize = '7pt';
    }

    private static pointForInfoLayer?: Point;

    /**
    * 코스의 Polyline 상에 현재 커서가 가리키는 지점의 정보를 표시하는 레이어를 띄운다.
    * @param point
    */
    private static setCursorInfoLayer(point: Point) {
        let course = ApplicationState.course;
        let nextPoint = course.getNextPointOfPosition(point.distanceFromCourseStart);
        let elevationAndSlope = course.getElevationAndSlopeOfPosition(point.distanceFromCourseStart);
        if (!nextPoint || !elevationAndSlope) {
            return;
        }

        this.pointForInfoLayer = point;
        this.createDivInfoLayer();

        let line = new Geometry.Line(new Geometry.Point(point.longitude, point.latitude), new Geometry.Point(nextPoint.longitude, nextPoint.latitude));
        this.spanInfoLayerDirection.style.transform = `rotate(${(-line.angleDegree).toFixed(0)}deg)`;

        let elevation = elevationAndSlope[0];
        let slope = elevationAndSlope[1];
        let components = [
            `${Resources.text.position}: ${(point.distanceFromCourseStart / 1000).toFixed(2)}km`,
            `${Resources.text.elevation}: ${elevation.toFixed(1)}m`,
            `${Resources.text.slope}: ${(slope * 100).toFixed(1)}%`,
        ];
        this.divInfoLayerText.innerHTML = components.join('<br />');
        let position = this.map.convertMapPositionToPixelPosition(LatLng.fromPoint(point));
        let x = position[0];
        let y = position[1];

        this.showDivInfoLayer(x, y);
        this.setCursorMarker(point);

        if (this.showingPointMenuController?.div.parentElement) {
            requestAnimationFrame(() => {
                let infoLayerRect = this.divInfoLayer.getBoundingClientRect();
                let showingPointMenuRect = this.showingPointMenuController.div.getBoundingClientRect();
                let position = this.map.convertMapPositionToPixelPosition(LatLng.fromPoint(point));
                let isMenuAnchorRight = position[0] > showingPointMenuRect.left;
                x = isMenuAnchorRight ? (showingPointMenuRect.right - infoLayerRect.width - 10) : showingPointMenuRect.left;
                y = Math.floor(showingPointMenuRect.top - 5 - infoLayerRect.height);
                this.showDivInfoLayer(x, y);
            });
        }
    }

    private static showDivInfoLayer(x: number, y: number) {
        HTMLUtility.showElementInContainer(
            this.divInfoLayer,
            this.map.div,
            x,
            y,
            MapConstants.zIndexForCursorInfoLayer,
            5
        );
    }

    private static resetDivInfoLayerPositionIfRequired() {
        if (!this.divInfoLayer?.parentElement || !this.pointForInfoLayer) {
            return;
        }
        this.setCursorInfoLayer(this.pointForInfoLayer);
    }

    private static removeCursorInfoLayer() {
        this.pointForInfoLayer = null;
        this.divInfoLayer?.remove();
    }

    /**
    * 경로상의 모든 영역이 모두 보이도록 지도를 조정한다.
    */
    static setVisibleAllPoints(points: Point[]) {
        let bounds = MapUtility.getBoundsFromPoints(points);
        if (!bounds) {
            return;
        }

        this.map.bounds = bounds;
        if (this.map.zoom > 15) {
            this.map.zoom = 15;
        }
        // Log.d(`show all points = ${north}, ${east}, ${south} ${west}`);
    }

    static openKakaoMap(position: LatLng) {
        window.open(
            `https://map.kakao.com/link/map/${position.latitude},${position.longitude}`,
            'ridingazua_kakaomap'
        );
    }

    static openKakaoMapRoadview(position: LatLng) {
        window.open(
            `https://map.kakao.com/link/roadview/${position.latitude},${position.longitude}`,
            'ridingazua_kakaomap'
        );
    }

    static showingPointMenuController?: MenuController;

    static showMenu(point: Point, menuItems: MenuItem[]) {
        let position = this.map.convertMapPositionToPixelPosition(LatLng.fromPoint(point));
        let x = position[0];
        let y = position[1];

        // Application에 document.onclick에 의해 MenuController.dismissAll() 처리가 있다.
        // MenuController.dismissAll 보다는 menu가 나중에 떠야하므로 지연시키는 처리.
        setTimeout((args) => {
            let menuController = new MenuController(menuItems);
            this.showingPointMenuController = menuController;
            let mapDiv = this.map.div;
            menuController.showIn(mapDiv, x, y);
            menuController.onDismiss = () => {
                ApplicationState.executeListeners(ApplicationEvent.REMOVE_CURSOR);
                this.showingPointMenuController = null;
            };
            this.setCursorInfoLayer(point);
        }, 0);
    }

    /**
    * 경로선(polyline)을 클릭시 노출할 메뉴 항목들
    * @param point
    * @param section
    */
    private static menusForSectionLine(point: Point, section: Section): MenuItem[] {
        let menuItems: MenuItem[] = [
            {
                id: 'addWayPoint',
                name: Resources.text.add_waypoint,
                forEditing: true,
                action: () => {
                    let virtualPointInfo = section.virtualPointInfoByCoord(LatLng.fromPoint(point));
                    WaypointDialogContorller.showUsingDistance(virtualPointInfo.point.distanceFromCourseStart);
                },
            },
            {
                id: 'addLeftWayPoint',
                name: sprintf(Resources.text.add_waypoint_type_format, Resources.text.waypoint_type_left),
                forEditing: true,
                action: () => {
                    let virtualPointInfo = section.virtualPointInfoByCoord(LatLng.fromPoint(point));
                    TaskManager.doTask(new AddWaypointTask(virtualPointInfo.point.distanceFromCourseStart, new Waypoint(WaypointType.Left, Resources.textForLanguage('en').waypoint_type_left)));
                },
            },
            {
                id: 'addRightWayPoint',
                name: sprintf(Resources.text.add_waypoint_type_format, Resources.text.waypoint_type_right),
                forEditing: true,
                action: () => {
                    let virtualPointInfo = section.virtualPointInfoByCoord(LatLng.fromPoint(point));
                    TaskManager.doTask(new AddWaypointTask(virtualPointInfo.point.distanceFromCourseStart, new Waypoint(WaypointType.Right, Resources.textForLanguage('en').waypoint_type_right)));
                },
            },
            {
                id: 'split',
                name: Resources.text.split_section,
                forEditing: true,
                action: () => {
                    TaskManager.doTask(new SplitTask(section, point.latitude, point.longitude));
                },
            },
            {
                id: 'drawLine',
                name: Resources.text.draw_line_to_this_point,
                forEditing: true,
                action: () => {
                    SectionEditor.selectedEditor.didMapClick(LatLng.fromPoint(point));
                },
            },
        ];

        let isSouthKorea = Utility.pointInPolygin([point.latitude, point.longitude], ApplicationState.boundsSouthKorea);
        if (isSouthKorea) {
            menuItems.push({
                id: 'kakaoMap',
                name: Resources.text.view_kakao_map_this_point,
                action: () => {
                    this.openKakaoMap(
                        LatLng.fromPoint(point)
                    );
                },
            });
            menuItems.push({
                id: 'kakaoMapRoadview',
                name: Resources.text.view_kakao_map_road_view_this_point,
                action: () => {
                    this.openKakaoMapRoadview(LatLng.fromPoint(point));
                },
            });
        }

        return menuItems.filter((menuItem) => {
            if (SectionEditor.isLocked) {
                return menuItem.forEditing != true;
            } else {
                return true;
            }
        });
    }

    /**
    * 선택된 지점에 섹션 편집용 메뉴를 표시한다.
    * @param position
    * @param section
    */
    static showMenuForSectionLine(position: LatLng, section: Section): boolean {
        if (!section) {
            return false;
        }

        let virtualPointInfo = ApplicationState.selectedSection.virtualPointInfoByCoord(position);
        if (!virtualPointInfo) {
            return false;
        }

        this.showMenu(virtualPointInfo.point, this.menusForSectionLine(virtualPointInfo.point, section));
        ApplicationState.executeListeners(ApplicationEvent.SET_CURSOR, virtualPointInfo.point);
        
        return true;
    }

    static showOverlayImageMapSettings() {
        OverlayImageMapSettingsDialogController.show();
    }
}

export class MapUtility {
    /**
    * bounds를 반환
    * @param points
    * @param margin 상대적인 값이다. 가로/세로 중 긴 축의 길이에 margin 을 곱해서 나온 값만큼 확장한 영역을 반환하게 된다.
    */
    static getBoundsFromPoints(points: Point[], margin: number = 0): LatLngBounds | null {
        if (!points || !points.length) {
            return null;
        }

        let south: number;
        let west: number;
        let north: number;
        let east: number;

        points.forEach((point) => {
            let latitude = point.latitude;
            let longitude = point.longitude;
            if (north == undefined || latitude > north) {
                north = latitude;
            }
            if (south == undefined || latitude < south) {
                south = latitude;
            }
            if (east == undefined || longitude > east) {
                east = longitude;
            }
            if (west == undefined || longitude < west) {
                west = longitude;
            }
        });

        let marginValue = 0;
        if (margin) {
            let longLength = Math.max(Math.abs(east - west), Math.abs(north - south));
            marginValue = longLength * margin;
        }

        return new LatLngBounds(
            south - marginValue,
            west - marginValue,
            north + marginValue,
            east + marginValue
        );
    }
}
