
import { Bounds, Point, Section } from "../common/Ridingazua.Model";
import { isNothing, Utility } from "../common/Ridingazua.Utility";
import { ApplicationEvent, ApplicationState } from "./Ridingazua.ApplicationState";
import { KeyState } from "./Ridingazua.KeyState";
import { LocationManager } from "./Ridingazua.LocationManager";
import { MapConstants } from "./Ridingazua.MapConstants";
import { MapController } from "./Ridingazua.MapController";
import { MapSettingsMenuController } from "./Ridingazua.MapSettingsMenuController";
import { MapType } from "./Ridingazua.MapType";
import { MenuController } from "./Ridingazua.MenuController";
import { OverlayImageMapSettingsDialogController } from "./Ridingazua.OverlayImageMapSettingsDialogController";
import { PlaceSearchController } from "./Ridingazua.PlaceSearchController";
import { SectionEditor } from "./Ridingazua.SectionEditor";
import { SectionListDialogController } from "./Ridingazua.SectionListDialogController";
import { SelectedRangeController } from "./Ridingazua.SelectedRangeController";
import { StorageController } from "./Ridingazua.StorageController";
import { WaypointDialogContorller } from "./Ridingazua.WaypointDialogController";

/**
 * 지도를 보여주기 위해 필요한 기능들
 */
export abstract class MapWrapper {
    abstract get mapProvider(): MapProvider;
    abstract get availableMapTypes(): MapType[];
    abstract get map(): any;
    abstract get div(): Element;
    abstract set mapType(mapType: MapType);
    abstract get center(): LatLng;
    abstract set center(latLng: LatLng);
    abstract get defaultZoomToPoint(): number;
    abstract get zoom(): number;
    abstract set zoom(zoom: number);
    abstract get bounds(): LatLngBounds;
    abstract set bounds(bounds: LatLngBounds);
    abstract pan(position: LatLng);
    abstract jump(position: LatLng, zoom: number);
    abstract setMyLocationMarker(position?: LatLng);
    abstract setCursorMarker(position?: LatLng);
    abstract createStartOrEndPointMarker(isStart: boolean, position: LatLng): MapMarker;
    abstract createWaypointMarker(point: Point): MapMarker;
    abstract createSelectedRangePolylines(path: LatLng[], section: Section): MapPolyline[];
    abstract createLoadingPolyline(path: LatLng[], isSelected: boolean): MapPolyline;
    abstract createDirectionPolylines(path: LatLng[], isSelected: boolean): MapPolyline[];
    abstract createSegmentPolylines(path: LatLng[], isSelected: boolean): MapPolyline[];
    abstract createPlaceMarker(name: string, index: number, position: LatLng, placeId?: string): MapMarker;
    abstract convertMapPositionToPixelPosition(position: LatLng): [number, number];
    abstract createSelectedBoundsRectangle(selectedBounds: Bounds): MapRectangle;
    abstract addOverlayImageMap();
    abstract removeOverlayImageMap();
    abstract shouldPolylineClick(position: LatLng): boolean;

    protected myLocationMarker?: MapMarker;
    protected cursorMarker?: MapMarker;

    private _placeMarkers?: MapMarker[];

    get placeMarkers(): MapMarker[] | null {
        return this._placeMarkers;
    }

    set placeMarkers(value: MapMarker[] | null) {
        this._placeMarkers?.forEach((marker) => {
            marker.map = null;
        });

        this._placeMarkers = value;
    }

    private _showingInfoWindow?: MapInfoWindow;

    set showingInfoWindow(value: MapInfoWindow | null) {
        this._showingInfoWindow?.close();
        this._showingInfoWindow = value;
        MapInfoWindow.showingPlaceId = value?.placeId;
    }

    protected get isOverlayImageMapEnabled(): boolean {
        let isEnabled = StorageController.get('overlay_image_map_enabled') || 'false';
        return isEnabled == 'true';
    }

    protected get overlayImageMapOpacity(): number {
        try {
            return parseInt(StorageController.get('overlay_image_map_opacity') || '5');
        } catch (error) {
            return 5;
        }
    }

    protected get overlayImageMapUrlTemplate(): string | null {
        let urlTemplate = StorageController.get(OverlayImageMapSettingsDialogController.keyForImageOverlayUrlTemplate) || '';
        if (!urlTemplate.length) {
            return null;
        }

        return urlTemplate;
    }

    removeMyLocationMarker() {
        if (!this.myLocationMarker) {
            return;
        }

        this.myLocationMarker.map = null;
        this.myLocationMarker = null;
    }

    saveMapCenterZoomToStorage() {
        let center = this.center;
        if (!center) {
            return;
        }

        let latitude = center.latitude;
        let longitude = center.longitude;
        let zoom = this.zoom;

        StorageController.set(
            'map_center_zoom',
            JSON.stringify({
                latitude: latitude,
                longitude: longitude,
                zoom: zoom,
            })
        );
    }

    loadMapCenterZoomFromStorage(): any {
        let jsonString = StorageController.get('map_center_zoom');

        if (!jsonString) {
            return;
        }

        let centerZoom;

        try {
            centerZoom = JSON.parse(jsonString);
        } catch {
            // do nothing
        }

        if (!centerZoom) {
            return null;
        }

        return centerZoom;
    }

    saveBoundsToStorage() {
        let bounds = this.bounds;

        if (!bounds) {
            return;
        }

        StorageController.set(
            'map_bounds',
            JSON.stringify(bounds.toJson())
        );
    }

    loadBoundsFromStorage(): LatLngBounds | null {
        let jsonString = StorageController.get('map_bounds');

        if (!jsonString) {
            return null;
        }

        let boundsJson;

        try {
            boundsJson = JSON.parse(jsonString);
        } catch {
            // do nothing
        }

        if (!boundsJson) {
            return null;
        }

        return LatLngBounds.fromJson(boundsJson);
    }

    onMapClick(position: LatLng) {
        if (MenuController.isShowing) {
            MenuController.dismissAll();
            return;
        }

        SectionEditor.selectedEditor.didMapClick(position);
    }

    onMapRightClick(position: LatLng) {
        this.showCursorMarker(position.toPoint());

        MapSettingsMenuController.showWithCoordinate(
            position,
            () => {
                this.showCursorMarker(null);
            }
        );
    }

    onCenterChanged() {
        MenuController.dismissAll();
        MapController.didMapCenterChange();
        PlaceSearchController.currentBounds = this.bounds;
        SelectedRangeController.updateSelectedRangeInfoLayerPosition();
    }

    onIdle() {
        MenuController.dismissAll();
        this.saveMapCenterZoomToStorage();
        this.saveBoundsToStorage();
    }

    onZoomChanged() {
        MenuController.dismissAll();
        MapController.didMapZoomChange();
        SelectedRangeController.updateSelectedRangeInfoLayerPosition();
    }

    onDragStart() {
        MenuController.dismissAll();
        LocationManager.clearWatch();
    }

    onCursorMarkerClick() {
        let position = this.cursorMarker.position;
        this.onPolylineClick(position);
    }

    onPolylineClick(position: LatLng) {
        if (MapController.showingPointMenuController?.div.parentElement) {
            MapController.showingPointMenuController?.dismiss();
            return;
        }

        if (KeyState.shiftKey) {
            SectionEditor.selectedEditor.didMapClick(position);
            return;
        }

        for (let section of ApplicationState.course.sections) {
            let virtualPointInfo = section.virtualPointInfoByCoord(position);
            if (virtualPointInfo) {
                SectionListDialogController.isDisableScrollToSelectedSection = true;
                if (section == ApplicationState.selectedSection) {
                    MapController.showMenuForSectionLine(position, section);
                } else {
                    ApplicationState.selectedSection = section;
                    SectionListDialogController.isDisableScrollToSelectedSection = false;
                    SectionListDialogController.scrollToSelectedSectionVisible(true);
                }
                return;
            }
        }
    }

    onCursorMarkerRightClick() {
        let position = this.cursorMarker.position;
        this.onPolylineRightClick(position);
    }

    onPolylineRightClick(position: LatLng) {
        for (let section of ApplicationState.course.sections) {
            let menuDidShow = MapController.showMenuForSectionLine(position, section);
            if (menuDidShow) {
                return;
            }
        }
    }

    onPolylineMousemove(section: Section, position: LatLng) {
        let virtualPointInfo = section.virtualPointInfoByCoord(position);
        this.showCursorMarker(virtualPointInfo.point);
    }

    onPolylineMouseout() {
        this.showCursorMarker(null);
    }

    onPlaceMarkerClick(marker: MapMarker, position: LatLng, name: string, zoom: number) {
        this.placeMarkers?.forEach((showingMarker) => {
            showingMarker.zIndex = MapConstants.zIndexForPlaceMarker;
        });

        marker.zIndex = MapConstants.zIndexForSelectedPlaceMarker;
        this.showInfoWindow(marker);
        this.jump(position, zoom);
    }

    showInfoWindow(marker: MapMarker) {
        let infoWindow = new MapInfoWindow(
            marker.createInfoWindow(marker.name),
            marker.placeId
        );
        infoWindow.open(this, marker);
        this.showingInfoWindow = infoWindow;
    }

    showInfoWindowFirstPlaceMarker() {
        if (!this.placeMarkers?.length) {
            return;
        }

        this.showInfoWindow(this.placeMarkers[0]);
    }

    showInfoWindowForPlaceId(placeId: string) {
        let filteredPlaceMarkers = this.placeMarkers.filter(
            (placeMarker) => {
                return placeMarker.placeId == placeId;
            }
        );

        if (!filteredPlaceMarkers.length) {
            return;
        }

        this.showInfoWindow(filteredPlaceMarkers[0]);
    }

    nearCourseVirtualPointFrom(position: LatLng): Point | null {
        let bounds = this.bounds;
        let pixelPosition = this.convertMapPositionToPixelPosition(position);

        let points = ApplicationState.course.allPoints();

        if (!points.length) {
            return;
        }

        let nearestDistance: number;
        let nearestPoints: Point[];

        for (let i = 1; i < points.length; i++) {
            let p1 = points[i - 1];
            let p2 = points[i];
            let isP1InBounds = bounds.contains(LatLng.fromPoint(p1));
            let isP2InBounds = bounds.contains(LatLng.fromPoint(p2));

            if (!isP1InBounds && !isP2InBounds) {
                continue;
            }

            let point1 = this.convertMapPositionToPixelPosition(LatLng.fromPoint(p1));
            let point2 = this.convertMapPositionToPixelPosition(LatLng.fromPoint(p2));

            let distance = Utility.pointLineDistance(
                pixelPosition[0],
                pixelPosition[1],
                point1[0],
                point1[1],
                point2[0],
                point2[1]
            );

            if (distance > 10) {
                continue;
            }

            if (isNothing(nearestDistance) || distance < nearestDistance) {
                nearestDistance = distance;
                nearestPoints = [p1, p2];
            }
        }

        if (!nearestPoints) {
            return null;
        }

        let p1 = nearestPoints[0];
        let p2 = nearestPoints[1];
        let distanceFromP1 = Utility.distanceMeterBetween(p1.latitude, p1.longitude, position.latitude, position.longitude);
        let distanceFromP2 = Utility.distanceMeterBetween(p2.latitude, p2.longitude, position.latitude, position.longitude);
        let ratio = distanceFromP1 / (distanceFromP1 + distanceFromP2);
        let distance = ((p2.distanceFromCourseStart - p1.distanceFromCourseStart) * ratio) + p1.distanceFromCourseStart;
        let virtualPoint = ApplicationState.course.getVirtualPointByDistance(distance);

        return virtualPoint;
    }

    nearWaypointFrom(position: LatLng): Point | null {
        let pixelPosition = this.convertMapPositionToPixelPosition(position);
        let pointsHasWaypoint = ApplicationState.course.pointsHasWaypoint();
        let nearestPoint: Point | null = null;
        let nearestPointDistance: number | null = null;
        pointsHasWaypoint.forEach((point) => {
            let waypointPixelPosition = this.convertMapPositionToPixelPosition(
                LatLng.fromPoint(point)
            );

            let distance = Utility.pointsDistance(
                pixelPosition[0],
                pixelPosition[1],
                waypointPixelPosition[0],
                waypointPixelPosition[1],
            );

            if (!nearestPointDistance || nearestPointDistance > distance) {
                nearestPoint = point;
                nearestPointDistance = distance;
            }
        });

        if (nearestPointDistance > 10) {
            return
        }

        return nearestPoint;
    }

    showCursorMarker(virtualPoint?: Point) {
        if (MapController.showingPointMenuController?.div.parentElement) {
            return;
        }

        if (virtualPoint) {
            ApplicationState.executeListeners(ApplicationEvent.SET_CURSOR, virtualPoint);
        } else {
            ApplicationState.executeListeners(ApplicationEvent.REMOVE_CURSOR);
        }
    }

    onWaypointClick(point: Point) {
        WaypointDialogContorller.showUsingPoint(point);
    }
}

export class LatLng {
    latitude: number
    longitude: number

    constructor(latitude: number, longitude: number) {
        this.latitude = latitude;
        this.longitude = longitude;
    }

    static fromGoogle(latLng: google.maps.LatLng): LatLng {
        return new LatLng(latLng.lat(), latLng.lng())
    }

    static fromKakao(latLng: kakao.maps.LatLng): LatLng {
        return new LatLng(latLng.getLat(), latLng.getLng());
    }

    static fromPoint(point: Point) {
        return new LatLng(point.latitude, point.longitude);
    }

    toGoogle(): google.maps.LatLng {
        return new google.maps.LatLng(this.latitude, this.longitude);
    }

    toKakao(): kakao.maps.LatLng {
        return new kakao.maps.LatLng(this.latitude, this.longitude);
    }

    toPoint(): Point {
        return new Point(this.latitude, this.longitude);
    }
}

export class LatLngBounds {
    south: number;
    west: number;
    north: number;
    east: number;

    constructor(south: number, west: number, north: number, east: number) {
        this.south = south;
        this.west = west;
        this.north = north;
        this.east = east;
    }

    /**
     * 비율에 따라 영역을 축소/확장 
     */
    scale(ratio: number): LatLngBounds {
        // 경계의 중앙 계산
        const centerLat = (this.north + this.south) / 2;
        const centerLng = (this.east + this.west) / 2;

        // 경계의 반경 계산 (위도에 따른 경도 보정)
        const latDiff = (this.north - this.south) / 2;
        const lngDiff = (this.east - this.west) / 2;

        // 위도에 따라 경도의 간격을 보정 (경도 간격은 위도에 따라 달라짐)
        const latRadian = centerLat * (Math.PI / 180);
        const lngDiffCorrected = lngDiff * Math.cos(latRadian);  // 위도에 따른 경도 간격 보정

        // 새로운 반경 계산
        const newLatDiff = latDiff * ratio;
        const newLngDiff = lngDiffCorrected * ratio;

        // 새로운 경계 계산
        return new LatLngBounds(
            centerLat - newLatDiff,
            centerLng - newLngDiff,
            centerLat + newLatDiff,
            centerLng + newLngDiff
        );
    }

    contains(position: LatLng): boolean {
        const isLatInRange = position.latitude >= this.south && position.latitude <= this.north;
        const isLngInRange = position.longitude >= this.west && position.longitude <= this.east;
        return isLatInRange && isLngInRange;
    }

    static fromGoogle(bounds: google.maps.LatLngBounds): LatLngBounds {
        return new LatLngBounds(
            bounds.getSouthWest().lat(),
            bounds.getSouthWest().lng(),
            bounds.getNorthEast().lat(),
            bounds.getNorthEast().lng()
        );
    }

    toGoogle(): google.maps.LatLngBounds {
        return new google.maps.LatLngBounds(
            new google.maps.LatLng(this.south, this.west),
            new google.maps.LatLng(this.north, this.east),
        );
    }

    static fromKakao(bounds: kakao.maps.LatLngBounds): LatLngBounds {
        return new LatLngBounds(
            bounds.getSouthWest().getLat(),
            bounds.getSouthWest().getLng(),
            bounds.getNorthEast().getLat(),
            bounds.getNorthEast().getLng()
        );
    }

    toKakao(): kakao.maps.LatLngBounds {
        return new kakao.maps.LatLngBounds(
            new kakao.maps.LatLng(this.south, this.west),
            new kakao.maps.LatLng(this.north, this.east),
        );
    }

    static fromJson(json: any): LatLngBounds | null {
        let south = json.south;
        let west = json.west;
        let north = json.north;
        let east = json.east;

        if (!south || !west || !north || !east) {
            return null;
        };

        return new LatLngBounds(south, west, north, east);
    }

    toJson(): any {
        return {
            south: this.south,
            west: this.west,
            north: this.north,
            east: this.east,
        }
    }
}

export class MapMarker {
    raw: any;
    placeId?: string;

    constructor(raw: any, placeId?: string) {
        this.raw = raw;
        this.placeId = placeId
    }

    get position(): LatLng {
        if (this.raw instanceof google.maps.Marker) {
            const marker: google.maps.Marker = this.raw;
            return LatLng.fromGoogle(
                marker.getPosition()
            );
        } else if (this.raw instanceof kakao.maps.Marker) {
            const marker: kakao.maps.Marker = this.raw;
            return LatLng.fromKakao(
                marker.getPosition()
            );
        } else {
            throw new Error(`unimplemented get position to MapMarker raw(${this.raw}).`);
        }
    }

    set position(position: LatLng) {
        if (this.raw instanceof google.maps.Marker) {
            const marker: google.maps.Marker = this.raw;
            marker.setPosition(
                position.toGoogle()
            );
        } else if (this.raw instanceof kakao.maps.Marker) {
            const marker: kakao.maps.Marker = this.raw;
            marker.setPosition(
                position.toKakao()
            );
        } else {
            throw new Error(`unimplemented set position to MapMarker raw(${this.raw}).`);
        }
    }

    set map(map: MapWrapper | null) {
        if (this.raw instanceof google.maps.Marker) {
            const marker: google.maps.Marker = this.raw;
            marker.setMap(map?.map);
        } else if (this.raw instanceof kakao.maps.Marker) {
            const marker: kakao.maps.Marker = this.raw;
            marker.setMap(map?.map);
        } else {
            throw new Error(`unimplemented set map to MapMarker raw(${this.raw}).`);
        }
    }

    set zIndex(zIndex: number | null) {
        if (this.raw instanceof google.maps.Marker) {
            const marker: google.maps.Marker = this.raw;
            marker.setZIndex(zIndex);
        } else if (this.raw instanceof kakao.maps.Marker) {
            const marker: kakao.maps.Marker = this.raw;
            marker.setZIndex(zIndex);
        } else {
            throw new Error(`unimplemented set zIndex to MapMarker raw(${this.raw})`);
        }
    }

    set cursor(cursor: string) {
        if (this.raw instanceof google.maps.Marker) {
            const marker: google.maps.Marker = this.raw;
            marker.setCursor(cursor);
        } else if (this.raw instanceof kakao.maps.Marker) {
            const marker: kakao.maps.Marker = this.raw;
            // marker.setCursor(cursor); // kakao maps는 marker에 cursor 속성이 없다.
        } else {
            throw new Error(`unimplemented set zIndex to MapMarker raw(${this.raw})`);
        }
    }

    get name(): string {
        if (this.raw instanceof google.maps.Marker) {
            const marker: google.maps.Marker = this.raw;
            return marker.getTitle();
        } else if (this.raw instanceof kakao.maps.Marker) {
            const marker: kakao.maps.Marker = this.raw;
            return marker.getTitle();
        } else {
            throw new Error(`unimplemented createInfoWindow to MapMarker raw(${this.raw})`);
        }
    }

    createInfoWindow(content: string): any {
        if (this.raw instanceof google.maps.Marker) {
            return new google.maps.InfoWindow({
                content: content
            });
        } else if (this.raw instanceof kakao.maps.Marker) {
            return new kakao.maps.InfoWindow({
                content: `<div style="padding:5px;">${content}</div>`,
                removable: true,
                zIndex: MapConstants.zIndexForPlaceMarkerInfoWindow
            });
        } else {
            throw new Error(`unimplemented createInfoWindow to MapMarker raw(${this.raw})`);
        }
    }
}

export class MapPolyline {
    raw: any;

    constructor(raw: any) {
        this.raw = raw;
    }

    set map(map: MapWrapper | null) {
        if (this.raw instanceof google.maps.Polyline) {
            const polyline: google.maps.Polyline = this.raw;
            polyline.setMap(map?.map);
        } else if (this.raw instanceof kakao.maps.Polyline) {
            const polyline: kakao.maps.Polyline = this.raw;
            polyline.setMap(map?.map);
        } else {
            throw new Error(`unimplemented MapPolyline set map to raw(${this.raw}).`)
        }
    }

    addListeners(map: MapWrapper, section: Section, isSelected: boolean) {
        if (this.raw instanceof google.maps.Polyline) {
            this.addListenersToGoogleMapsPolyline(map, section, isSelected);
        } else if (this.raw instanceof kakao.maps.Polyline) {
            this.addListenersToKakaoMapsPolyline(map, section, isSelected);
        } else {
            throw new Error(`unimplemented MapPolyline addListeners to raw(${this.raw}).`)
        }
    }

    private addListenersToGoogleMapsPolyline(map: MapWrapper, section: Section, isSelected: boolean) {
        const polyline = this.raw as google.maps.Polyline;

        polyline.addListener('mousemove', (event) => {
            let latLng = event.latLng as google.maps.LatLng;
            this.onMouseMove(section, LatLng.fromGoogle(latLng));
        });

        polyline.addListener('mouseout', (event) => {
            this.onMouseOut();
        });

        polyline.addListener('click', (event) => {
            let latLng = event.latLng as google.maps.LatLng;
            if (!map.shouldPolylineClick(LatLng.fromGoogle(latLng))) {
                // do nothing
            } else {
                this.onClick(section, LatLng.fromGoogle(latLng), isSelected);
            }
        });

        polyline.addListener('rightclick', (event) => {
            let latLng = event.latLng as google.maps.LatLng;
            this.onRightClick(section, LatLng.fromGoogle(latLng));
        });
    }

    private addListenersToKakaoMapsPolyline(map: MapWrapper, section: Section, isSelected: boolean) {
        const polyline = this.raw as kakao.maps.Polyline;

        kakao.maps.event.addListener(polyline, 'mousemove', (mouseEvent) => {
            let latLng = mouseEvent.latLng as kakao.maps.LatLng;
            this.onMouseMove(section, LatLng.fromKakao(latLng));
            kakao.maps.event.preventMap();
        });

        kakao.maps.event.addListener(polyline, 'mouseout', () => {
            this.onMouseOut();
            kakao.maps.event.preventMap();
        });

        kakao.maps.event.addListener(polyline, 'click', (mouseEvent) => {
            let latLng = mouseEvent.latLng as kakao.maps.LatLng;

            if (!map.shouldPolylineClick(LatLng.fromKakao(latLng))) {
                // do nothing
            } else {
                this.onClick(section, LatLng.fromKakao(latLng), isSelected);
            }

            kakao.maps.event.preventMap();
        });

        kakao.maps.event.addListener(polyline, 'rightclick', (mouseEvent) => {
            let latLng = mouseEvent.latLng as kakao.maps.LatLng;
            this.onRightClick(section, LatLng.fromKakao(latLng));
            kakao.maps.event.preventMap();
        });
    }

    private onMouseMove(section: Section, position: LatLng) {
        this.showCursorMarker(section, position);
    }

    private onMouseOut() {
        ApplicationState.map.showCursorMarker(null);
    }

    private onClick(section: Section, position: LatLng, isSelected: boolean) {
        if (MapController.showingPointMenuController?.div.parentElement) {
            MapController.showingPointMenuController?.dismiss();
            return;
        }

        if (KeyState.shiftKey) {
            SectionEditor.selectedEditor.didMapClick(position);
            return;
        }

        if (!isSelected) {
            ApplicationState.selectedSection = section;
            return;
        }

        MapController.showMenuForSectionLine(
            position,
            section
        );
    }

    private onRightClick(section: Section, position: LatLng) {
        MapController.showMenuForSectionLine(
            position,
            section
        );
    }

    private showCursorMarker(section: Section, position: LatLng) {
        let virtualPointInfo = section.virtualPointInfoByCoord(position);

        if (!virtualPointInfo) {
            return;
        }

        ApplicationState.map.showCursorMarker(virtualPointInfo.point);
    }
}

export class MapRectangle {
    raw: any;

    constructor(raw: any) {
        this.raw = raw;
    }

    set map(map: MapWrapper | null) {
        if (this.raw instanceof google.maps.Rectangle) {
            const rectangle: google.maps.Rectangle = this.raw;
            rectangle.setMap(map?.map);
        } else if (this.raw instanceof kakao.maps.Rectangle) {
            const rectangle: kakao.maps.Rectangle = this.raw;
            rectangle.setMap(map?.map);
        } else {
            throw new Error(`unimplemented MapRectangle set map to raw(${this.raw}).`);
        }
    }
}

export class MapInfoWindow {
    raw: any;
    placeId?: string;

    static showingPlaceId?: string;

    constructor(raw: any, placeId?: string) {
        this.raw = raw;
        this.placeId = placeId;
    }

    open(map: MapWrapper, marker: MapMarker) {
        if (this.raw instanceof google.maps.InfoWindow) {
            let infoWindow: google.maps.InfoWindow = this.raw;
            infoWindow.setOptions({ disableAutoPan: true });
            infoWindow.open(map.map, marker.raw);
        } else if (this.raw instanceof kakao.maps.InfoWindow) {
            let infoWindow: kakao.maps.InfoWindow = this.raw;
            infoWindow.open(map.map, marker.raw);
        } else {
            throw new Error(`unimplemented MapInfoWindow open to raw(${this.raw}).`);
        }
    }

    close() {
        if (this.raw instanceof google.maps.InfoWindow) {
            let infoWindow: google.maps.InfoWindow = this.raw;
            infoWindow.close();
        } else if (this.raw instanceof kakao.maps.InfoWindow) {
            let infoWindow: kakao.maps.InfoWindow = this.raw;
            infoWindow.close();
        } else {
            throw new Error(`unimplemented MapInfoWindow close to raw(${this.raw}).`);
        }
    }
}

/**
 * 지도 서비스 제공자
 */
export enum MapProvider {
    GOOGLE, KAKAKO
}