import { MenuController, MenuItem } from './Ridingazua.MenuController';
import { Resources } from './Ridingazua.Resources';
import { MapController } from './Ridingazua.MapController';
import { AutoRouteDirector, AutoRouteProfile } from './Ridingazua.Director';
import { ApplicationState } from './Ridingazua.ApplicationState';
import { CommonDialogController } from './Ridingazua.CommonDialogController';
import { Statics } from '../common/Ridingazua.Statics';
import { isNothing } from '../common/Ridingazua.Utility';
import { MapType, MapTypeHelper } from './Ridingazua.MapType';
import { LatLng } from './Ridingazua.MapWrapper';

export class MapSettingsMenuController {
    private static instance: MapSettingsMenuController;
    private menuController: MenuController;

    private constructor() {
        this.menuController = new MenuController([]);
    }

    private update(position: LatLng | null, useSubmenus: boolean) {
        let menuItemsWithSubmenus: MenuItem[] = [
            {
                id: 'mapType',
                name: Resources.text.map_type,
                subMenuItems: this.mapTypeMenuItems()
            },
            {
                id: 'routeProfile',
                name: Resources.text.auto_route_profile,
                subMenuItems: [
                    {
                        id: AutoRouteDirector.autoRouteProfileIdForGraphhopper(AutoRouteProfile.OSM_CYCLING_RECOMMENDED),
                        name: Resources.text.auto_route_profile_cycling_recommended,
                        action: () => {
                            AutoRouteDirector.selectedAutoRouteProfile = AutoRouteProfile.OSM_CYCLING_RECOMMENDED;
                        },
                    },
                    {
                        id: AutoRouteDirector.autoRouteProfileIdForGraphhopper(AutoRouteProfile.OSM_CYCLING_MOUNTAIN),
                        name: Resources.text.auto_route_profile_cycling_mountain,
                        action: () => {
                            AutoRouteDirector.selectedAutoRouteProfile = AutoRouteProfile.OSM_CYCLING_MOUNTAIN;
                        },
                    },
                    {
                        id: AutoRouteDirector.autoRouteProfileIdForGraphhopper(AutoRouteProfile.OSM_ROAD),
                        name: Resources.text.auto_route_profile_road,
                        action: () => {
                            AutoRouteDirector.selectedAutoRouteProfile = AutoRouteProfile.OSM_ROAD;
                        },
                    }
                ],
            },
            {
                id: 'autoLeftRight',
                name: Resources.text.auto_left_right,
                subMenuItems: [
                    {
                        id: 'autoLeftRightEnabled',
                        name: Resources.text.enabled,
                        action: () => {
                            CommonDialogController.showConfirm(Resources.text.caution, new URL(Statics.localizedPageUrl('caution_auto_left_right')), [
                                {
                                    text: Resources.text.yes,
                                    action: () => {
                                        AutoRouteDirector.autoLeftRightWaypointEnabled = true;
                                    },
                                },
                                {
                                    text: Resources.text.no,
                                },
                            ]);
                        },
                    },
                    {
                        id: 'autoLeftRightDisabled',
                        name: Resources.text.disabled,
                        action: () => {
                            AutoRouteDirector.autoLeftRightWaypointEnabled = false;
                        },
                    },
                ],
            },
        ];

        let allSubMenuItems: MenuItem[] = [];
        for (let menuItem of menuItemsWithSubmenus) {
            for (let subMenuItem of menuItem.subMenuItems || []) {
                allSubMenuItems.push(subMenuItem);
            }
        }

        let menuItems = menuItemsWithSubmenus;
        if (!useSubmenus) {
            menuItems = [];
            for (let menuItem of menuItemsWithSubmenus) {
                menuItem.isHeader = true;
                menuItems.push(menuItem);
                for (let subMenuItem of menuItem.subMenuItems || []) {
                    menuItems.push(subMenuItem);
                }
                menuItem.subMenuItems = null;
            }
        }

        if (!isNothing(position)) {
            menuItems.push({
                id: 'kakaoMap',
                name: Resources.text.view_kakao_map_this_point,
                action: () => {
                    MapController.openKakaoMap(position);
                },
            });
            menuItems.push({
                id: 'kakaoMapRoadview',
                name: Resources.text.view_kakao_map_road_view_this_point,
                action: () => {
                    MapController.openKakaoMapRoadview(position);
                },
            });
        }

        for (let menuItem of allSubMenuItems) {
            let isSelectedItem =
                menuItem.id == MapTypeHelper.mapTypeId(MapController.selectedMapType) || // 지도 유형
                menuItem.id == AutoRouteDirector.autoRouteProfileIdForGraphhopper(AutoRouteDirector.selectedAutoRouteProfile) || // 자동경로 프로필
                (menuItem.id == 'autoLeftRightEnabled' && AutoRouteDirector.autoLeftRightWaypointEnabled) || // 자동 좌우회전 표시 켬
                (menuItem.id == 'autoLeftRightDisabled' && !AutoRouteDirector.autoLeftRightWaypointEnabled); // 자동 좌우회전 표시 끔

            if (isSelectedItem) {
                menuItem.isSelected = true;
            }
        }

        this.menuController.menuItems = menuItems;
    }

    private mapTypeMenuItems(): MenuItem[] {
        let menuItems = MapTypeHelper.allTypes.map(mapType => {
            return this.mapTypeMenuItem(mapType);
        });

        if (MapTypeHelper.googleBasedTypes.includes(MapController.selectedMapType)) {
            menuItems.push(
                this.overlayImageMapMenu()
            );
        }

        return menuItems;
    }

    private overlayImageMapMenu(): MenuItem {
        return {
            id: MapTypeHelper.mapTypeId(MapType.OVERLAY_IMAGE_MAP).toString(),
            name: Resources.text.map_type_overlay_image_map,
            action: () => {
                MapController.showOverlayImageMapSettings();
            },
        }
    }

    private get googleBasedMapTypeItems(): MenuItem[] {
        const googleBasedMapTypeItems = MapTypeHelper.googleBasedTypes.map((mapType) => {
            return this.mapTypeMenuItem(mapType);
        });

        googleBasedMapTypeItems.push(
            {
                id: MapTypeHelper.mapTypeId(MapType.OVERLAY_IMAGE_MAP).toString(),
                name: Resources.text.map_type_overlay_image_map,
                action: () => {
                    MapController.showOverlayImageMapSettings();
                },
            }
        );

        return googleBasedMapTypeItems;
    }

    private get kakaoBasedMapTypeItems(): MenuItem[] {
        return MapTypeHelper.kakaoBasedTypes.map((mapType) => {
            return this.mapTypeMenuItem(mapType);
        });
    }

    private mapTypeMenuItem(mapType: MapType): MenuItem {
        return {
            id: MapTypeHelper.mapTypeId(mapType).toString(),
            name: MapTypeHelper.mapTypeName(mapType),
            action: () => {
                MapController.selectedMapType = mapType;
            },
        }
    }

    private showWithCoordinate(position: LatLng, onDismiss?: () => void) {
        this.update(position, true);

        let pixelPosition = ApplicationState.map.convertMapPositionToPixelPosition(position);
        let x = pixelPosition[0];
        let y = pixelPosition[1];

        let mapDiv = ApplicationState.map.div;
        this.menuController.showIn(mapDiv, x, y);
        this.menuController.onDismiss = onDismiss;
    }

    private toggleWithElement(element: HTMLElement) {
        if (this.menuController.isShowing) {
            this.menuController.dismiss();
            return;
        }

        this.update(null, false);
        this.menuController.show(element);
    }

    public static showWithCoordinate(position: LatLng, onDismiss?: () => void) {
        if (!this.instance) {
            this.instance = new MapSettingsMenuController();
        }

        this.instance.showWithCoordinate(position, onDismiss);
    }

    public static toggleWithElement(element: HTMLElement) {
        if (!this.instance) {
            this.instance = new MapSettingsMenuController();
        }

        this.instance.toggleWithElement(element);
    }
}